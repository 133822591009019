const languages = ['ar', 'en'] as const;

export type Languages = typeof languages;
export type Language = Languages[number];

export const languagesDefault = 'en' as Language;
export const languagesSupported = [...languages];

//
//

//

export const getSupportedLanguage = (val?: string) => {
  if (!val) return undefined;
  const lang = String(val).toLowerCase() as Language;

  return languagesSupported.includes(lang) ? lang : undefined;
};

export const getSupportedLanguageFromRequest = (request: Request) => {
  const { pathname } = new URL(request.url);
  const pathLang = pathname.split('/')?.[1];

  return getSupportedLanguage(pathLang); // || await i18next.getLocale(request);
};

export const getLangDirection = (lang: Language) => (lang === 'ar' ? 'rtl' : 'ltr');

export const replaceUnknownLanguageFromRequest = (request: Request, target?: string) => {
  const { pathname } = new URL(request.url);
  const pathLang = pathname.split('/')?.[1];

  return pathname.replace(new RegExp(`^/${pathLang}`), target || languagesDefault);
};
